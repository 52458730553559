import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '@ecommerce/shared'
import { graphql } from 'gatsby'
import LayoutStaticPage from '../components/Layout/LayoutStaticPage'
import withPageTransition from '../components/withPageTransition'
import { StaticPage } from '../types/PgPages'
import { useStaticPageTemplate } from '../hooks/useStaticPageTemplate'

const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.medium};
  min-height: calc(100vh - 234px);

  @media (${breakpoints.tabletPortrait.min}) {
    min-height: calc(100vh - 206px);
  }

  @media (${breakpoints.desktop.min}) {
    min-height: calc(100vh - 257px);
  }
`

const Container = styled.div`
  max-width: 794px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  padding: 40px 16px;

  h2 {
    color: ${(props) => props.theme.colors.primary};
    font-size: 34px;
    margin-bottom: 0.5em;
    margin-top: 0;
  }

  h3 {
    color: ${(props) => props.theme.colors.primary};
    font-size: 24px;
    margin-bottom: 0.5em;
  }

  h4 {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  p {
    font-size: 17px;
    letter-spacing: -0.24px;
    margin-top: 0;
    line-height: 1.4;
  }

  img {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  ul {
    padding-inline-start: 16px;
  }

  li {
    padding-bottom: 16px;
  }

  a {
    text-decoration: none;
    font-weight: bold;
    color: black;
  }

  @media (${breakpoints.tabletPortrait.min}) {
    padding: 50px 100px;
  }

  @media (${breakpoints.desktop.min}) {
    padding: 50px 100px;
  }
`

function PrivacyPage(pageData: StaticPage) {
  const { pageTitle, title, preTitle, displayContent } = useStaticPageTemplate(pageData)
  return (
    <LayoutStaticPage title={pageTitle}>
      <Wrapper>
        <Container>
          <h4>{preTitle}</h4>
          <h2>{title}</h2>
          {displayContent()}
        </Container>
      </Wrapper>
    </LayoutStaticPage>
  )
}

export default withPageTransition(PrivacyPage)

export const query = graphql`
  query PrivacyPolicyQuery($privacyPolicyId: String!) {
    allContentfulPgPage(filter: { contentful_id: { eq: $privacyPolicyId } }) {
      edges {
        node {
          id
          title
          template {
            ... on ContentfulTmStaticPage {
              id
              name
              title
              preTitle
              content {
                json
              }
              customHtml {
                customHtml
              }
            }
          }
        }
      }
    }
  }
`
